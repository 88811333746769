import { Base64 } from 'js-base64';
import { bus } from '../main.js';
class UtilService {
    httpOptions = {}

    setHttpOptions() {
        if (localStorage.getItem('account_token') != null && localStorage.getItem('account_token') != undefined) {
            this.httpOptions = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('account_token')
                }
            }
        }
    }

    loadingContainer() {
        bus.$emit('loadingGlobalContainer');
        // const appLoading = document.getElementById('onboarding')
        // appLoading.style.display = 'block';
    }

    getDecodedToken(token) {
        let base64Token = token.split('.')[1];
        return JSON.parse(Base64.decode(base64Token));
    }

    generalError(error) {
        let errorResponse = {
            status: 0,
            message: [],
            errorDictionary: [],
            data: {}
        }

        if (error.response.status) {
            errorResponse.status = error.response.status
            if (error.response.data)
                errorResponse.data = error.response.data;
            if (error.response.status == 400) {
                if (error.response.data.message) {
                    errorResponse.message.push(error.response.data.message)
                }
                else if (error.response.data.errors) {
                    let errorsKeys = Object.keys(error.response.data.errors)
                    let allErrors = [];
                    errorsKeys.forEach(key => {
                        allErrors.push(...error.response.data.errors[key].toString().split(","));
                    })
                    errorResponse.message = allErrors
                    errorResponse.errorDictionary = errorsKeys;
                }
                else if (error.response.data.ErrorMessage) {
                    errorResponse.message.push(error.response.data.ErrorMessage)
                }
                else {
                    let errorsKeys = Object.keys(error.response.data)
                    let allErrors = [];
                    errorsKeys.forEach(key => {
                        allErrors.push(...error.response.data[key].toString().split(","));
                    })
                    errorResponse.message = allErrors
                    errorResponse.errorDictionary = errorsKeys;
                }
            }
            else if (error.response.status == 303) {
                if (error.response.data.message) {
                    errorResponse.message.push(error.response.data.message);
                }
            }
            else if (error.response.status == 401 || error.response.status == 403) {
                errorResponse.message.push('Não foi possível realizar a operação.');
            }
            else {
                errorResponse.message.push('Algo inesperado aconteceu.');
            }

            return errorResponse;
        }
    }
}

export default new UtilService();