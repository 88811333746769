<template>
  <div id="loading-container">
    <b-spinner label="Loading..." variant="primary"></b-spinner>
  </div>
</template>

<script>
export default {
  name: 'loading-container',
};
</script>

<style lang="scss" scoped>
div {
  height: 100vh;
  width: 100%;
  position: fixed;
  background: #fff;
  z-index: 99;
  display: none;
  .spinner-border {
    width: 4rem;
    height: 4rem;
    position: absolute;
    left: calc(50% - 45px);
    top: 37%;
  }
}
</style>