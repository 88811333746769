<template>
  <div :class="{ dontHaveElements: error.message.length == 0 }">
    <p
      v-for="(msgError, indexMsgError) in error.message"
      :key="indexMsgError"
      class="text-danger text-patternOne"
    >
      {{ msgError }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'error-message',
  props: {
    error: { type: Object, required: true },
  },
};
</script>

<style lang="scss" scoped>
div {
  &.dontHaveElements {
    height: 24px; //height: 28px;
    @media (max-width: 768px) {
      heigth: 16px; //height: 18px;
    }
  }

  p:last-child {
    margin-bottom: 0;
  }
}
</style>