import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
export const bus = new Vue();

Vue.config.productionTip = false;

//globalComponents
import './globalComponents.js';

// axios
import axios from './axios.js'
Vue.prototype.$http = axios
import util from './services/util.js';
Vue.prototype.$utilService = util;

import PortalVue from 'portal-vue'

Vue.use(PortalVue)

// Styles: SCSS

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/scss/main.scss';

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
