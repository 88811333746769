import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '*',
    redirect: '/error-404'
  },
  {
    path: '/',
    name: 'guardRoutes',
    component: () => import('@/views/onboarding/OnboardingRoot.vue'),
    redirect: `verify-email/${localStorage.getItem('access_token') ? localStorage.getItem('access_token') : ''}`,
    children: [
      {
        path: 'verify-email/:accessToken',
        name: 'VerifyEmail',
        component: () => import('@/views/onboarding/VerifyEmail.vue'),
      },
      {
        path: 'activate-by-account',
        name: 'ActivateByAccount',
        component: () => import('@/views/onboarding/ActivateByAccount.vue'),
      },
      {
        path: 'forgot-password',
        name: 'ForgotPassword',
        component: () => import('@/views/onboarding/ForgotPassword.vue'),
      },
      {
        path: 'activate-without-account',
        name: 'ActivateWithoutAccount',
        component: () => import('@/views/onboarding/ActivateWithoutAccount.vue'),
      },
      {
        path: 'validate-email',
        name: 'ValidateEmail',
        component: () => import('@/views/onboarding/ValidateEmail.vue'),
      },
      {
        path: 'user-link-conditions',
        name: 'UserLinkConditions',
        component: () => import('@/views/onboarding/UserLinkConditions.vue'),
      },
      {
        path: 'choose-profile',
        name: 'ChooseProfile',
        component: () => import('@/views/onboarding/ChooseProfile.vue'),
      },
    ]
  },
  {
    path: '/error-404',
    name: 'Error404',
    component: () => import('@/views/Error404.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
